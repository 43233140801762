// -----------------------------------------------------------------------------
// Demo preview
// -----------------------------------------------------------------------------

.demo {
  &__title {
    background: radial-gradient(111% 111% at 74.29% -11%, #a93300 0%, #005570 100%), linear-gradient(127.43deg, #00d5c8 0%, #20a 100%);
    background-blend-mode: difference, normal;
  }

  &__primary-hue {
    width: 240px;
    accent-color: var(--g-color-primary);
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    gap: var(--g-spacer-200);
    align-items: flex-start;
  }

  &__bar {
    display: inline-block;
    height: 10px;
    background-color: var(--g-color-primary);
  }

  &__value {
    display: inline-block;
    flex-shrink: 0;
    margin-left: var(--g-spacer-200);
    padding: var(--g-spacer-50) var(--g-spacer-100);
    color: var(--g-gray-100);
    font-size: var(--g-fs-200);
    line-height: 16px;
    background-color: var(--g-gray-700);
    border-radius: var(--g-rounded-100);
  }
}
