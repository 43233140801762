// -----------------------------------------------------------------------------
// Base CSS
// -----------------------------------------------------------------------------

html {
  color: var(--g-text-color);
  font-size: var(--g-fs);
  font-family: var(--g-font-family-primary);
  background-color: var(--g-bg-body-color);
  transition: color var(--g-transition-duration) var(--g-transition-type),
    background-color var(--g-transition-duration) var(--g-transition-type);
  scrollbar-gutter: stable;

  @media (prefers-reduced-motion: no-preference) {
    scroll-behavior: smooth;
  }
}

a:not([class]) {
  text-decoration-thickness: max(0.08em, 1px);
  text-underline-offset: 0.25em;
}
