@use "sass:color";

// -----------------------------------------------------------------------------
// Buttons
// -----------------------------------------------------------------------------

.btn {
  @include g-text-style("text-button");

  display: inline-flex;
  gap: var(--g-controls-padding-x);
  align-items: center;
  justify-content: center;
  padding: var(--g-controls-padding-y) var(--g-controls-padding-x);
  background-color: transparent;
  border: var(--g-border);
  border-radius: var(--g-border-radius);
  transition: background-color var(--g-transition), color var(--g-transition);
  user-select: none;

  &:not(:disabled) {
    cursor: pointer;
  }

  &[disabled] {
    &,
    &:hover {
      color: var(--g-gray-500);
      background: var(--g-gray-100);
      border-color: var(--g-gray-300);
      cursor: not-allowed;
    }
  }

  &[data-variant="primary"] {
    color: var(--g-color-white);
    background-color: var(--g-color-primary);
    border-color: var(--g-color-primary);

    &:hover {
      background: var(--g-color-primary-dark);
      border-color: var(--g-color-primary-dark);
    }
  }

  &[data-variant="secondary"] {
    color: var(--g-color-primary);
    background-color: var(--g-color-white);
    border-color: var(--g-color-primary);

    &:hover {
      background: var(--g-color-primary-dark);
      border-color: var(--g-color-primary-dark);
    }
  }
}
