// -----------------------------------------------------------------------------
// Shadows
// -----------------------------------------------------------------------------

.shadow {
  box-shadow: var(--g-shadow);
}

@each $name, $value in $g-shadows {
  .shadow-#{$name} {
    box-shadow: $value !important;
  }
}
