.prose {
  p,
  ul {
    --g-gutter: var(--g-spacer-300);
  }

  h1 {
    --g-gutter: var(--g-spacer-700);
  }

  h2 {
    --g-gutter: var(--g-spacer-600);
  }

  h3 {
    --g-gutter: var(--g-spacer-500);
  }
}
