// -----------------------------------------------------------------------------
// Display utilities
// -----------------------------------------------------------------------------

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.d-grid {
  display: grid;
}

.d-inline-grid {
  display: inline-grid;
}

// Horizontal stack

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

// Vertical stack

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

// Center all

.grid-center {
  display: grid;
  place-items: center;
}
