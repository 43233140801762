// -----------------------------------------------------------------------------
// Header
// -----------------------------------------------------------------------------

.app-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: var(--g-gutter);
}
