:root {
  // -----------------------------------------------------------------------------
  // Fluid spacers
  // -----------------------------------------------------------------------------

  --g-spacer-50: 0.25rem; // 4px
  --g-spacer-100: #{g-fluid(4px, 8px)};
  --g-spacer-200: #{g-fluid(6px, 12px)};
  --g-spacer-300: #{g-fluid(8px, 16px)};
  --g-spacer-400: #{g-fluid(12px, 24px)};
  --g-spacer-500: #{g-fluid(16px, 32px)};
  --g-spacer-600: #{g-fluid(24px, 48px)};
  --g-spacer-700: #{g-fluid(32px, 64px)};
  --g-spacer-800: #{g-fluid(48px, 96px)};
  --g-spacer-900: #{g-fluid(96px, 128px)};
  --g-spacer-1000: #{g-fluid(128px, 256px)};

  // -----------------------------------------------------------------------------
  // Fluid typography font sizes
  // -----------------------------------------------------------------------------

  --g-fs-100: 0.625rem; // 10px
  --g-fs-200: #{g-fluid(11px, 12px)};
  --g-fs-300: #{g-fluid(13px, 14px)};
  --g-fs-400: #{g-fluid(14px, 16px)};
  --g-fs-500: #{g-fluid(16px, 18px)};
  --g-fs-600: #{g-fluid(18px, 22px)};
  --g-fs-700: #{g-fluid(20px, 24px)};
  --g-fs-800: #{g-fluid(22px, 28px)};
  --g-fs-900: #{g-fluid(24px, 38px)};
  --g-fs-1000: #{g-fluid(32px, 58px)};

  // -----------------------------------------------------------------------------
  // Colors
  // -----------------------------------------------------------------------------

  // Blue used for primary color
  --g-color-blue-h: 200deg;
  --g-color-blue-s: 80%;
  --g-color-blue-l: 50%;

  // Base
  --g-color-white: #fff;
  --g-color-black: #000;
  --g-color-blue: #40a6ce;
  --g-color-green: #390;
  --g-color-orange: #ec942c;
  --g-color-red: #f02c2c;
}
